import { ReactNode } from 'react'

import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'

import { SectionTitle as SecTitle } from '@/stories/typography'
import { breakpoint } from '@/theme'

export const GridWrapper = styled.div`
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr;
`

export const Section = ({
  alternateBackground,
  children,
  componentStyle,
  sectionText,
  sectionTitle,
  titleAlignment,
  testId
}: {
  alternateBackground?: boolean
  children: ReactNode
  componentStyle?: SerializedStyles
  sectionText?: string
  sectionTitle?: string
  titleAlignment?: '' | 'left' | 'center' | 'right'
  testId?: string
}) => (
  <ContentSection css={componentStyle} alternateBackground={alternateBackground} data-e2eid={testId || ''}>
    <ContentWrapper>
      {sectionTitle && (
        <SectionTitle className="marketing-section-title" alignment={titleAlignment}>
          {sectionTitle}
        </SectionTitle>
      )}
      {sectionText && <SectionText dangerouslySetInnerHTML={{ __html: sectionText }} />}
      {children}
    </ContentWrapper>
  </ContentSection>
)

const ContentSection = styled.section<{ alternateBackground: boolean; css: SerializedStyles }>`
  margin-bottom: 8rem;
  ${({ alternateBackground }) =>
    alternateBackground &&
    `
    &:nth-of-type(even) {
      background-color: #fafafa;
      padding: 6rem 0;
    }
  `}
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  padding: 0 28px;
  max-width: ${breakpoint.min.xl}px;
`

const SectionTitle = styled(SecTitle)<{ alignment?: string }>`
  margin-bottom: 42px;
  text-align: ${({ alignment }) => alignment};
`

const SectionText = styled.div<{ alignment?: string }>`
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 24px;
  text-align: ${({ alignment }) => alignment};
`
