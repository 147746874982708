import Colors from '@microcomponents/colors'

export const TEST_IDS = {
  PAGE: 'homePage',
  THREE_COLUMN_LAYOUT: 'threeColumnLayout',
  FOUR_TILE_LAYOUT: 'fourTileLayout',
  PRODUCT_GALLERY: 'productGallery',
  LOGO_GALLERY: 'logoGallery',
  SHOP_NOW: 'shopNow'
}

export const colorMap = {
  black: Colors.homepageRedesign[0],
  yellow: Colors.homepageRedesign[1],
  white: Colors.homepageRedesign[2],
  blue: Colors.homepageRedesign[5],
  red: Colors.homepageRedesign[6],
  lightGrey: Colors.homepageRedesign[7]
}
