import { track } from 'analytics'

// NOTE: Not every element that uses this function contains bannerItem.
//       Every other argument should be provided in function call.
export default function onClickEvents(
  bannerName = '',
  redirectLocation = '/menu',
  toggleAddress,
  bannerItem = 'not_provided'
) {
  track('Banner.Click', { bannerName: bannerName, bannerItem: bannerItem })
  toggleAddress(redirectLocation)
}
